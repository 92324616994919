.wrapper {
  padding: 24px;

  .form {
    @media only screen and (min-width: 800px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .label {
        white-space: nowrap !important;
      }
    }
  }

  .input {
    margin-top: 32px;
  }

  .controller {
    position: fixed;
    bottom: 24px;
    width: calc(100% - 48px);

    @media only screen and (min-width: 800px) {
      width: fit-content;
      position: relative;
      bottom: 0;
      margin: auto;
      margin-top: 24px;
    }
  }
}
