.wrapper {
  overflow: auto;
  height: calc(100vh - 74px);
  max-width: 800px;
  width: 100%;
  margin: auto;

  .title {
    @media only screen and (min-width: 800px) {
      // text-align: center;
    }
  }

  .blockCode {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    width: 100%;

    @media only screen and (min-width: 800px) {
      width: 400px;
    }
  }

  .paymentInfo {
    margin-top: 12px;
    padding: 18px 24px 0 24px;
    background: var(--overlay-bg-color);

    @media only screen and (min-width: 800px) {
      background-color: transparent;
    }

    .blockInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0;
      border-bottom: 1px solid var(--secondary-text-color);
    }

    .moreDetail {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      gap: 4px;

      .icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .paymentSelector {
    padding: 16px 0;

    @media only screen and (min-width: 800px) {
      max-width: 400px;
      margin: auto;
    }
  }

  .controller {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 48px);
    padding: 12px 24px 24px 24px;
    position: fixed;
    bottom: 0;
    gap: 12px;
    background: var(--white-text-color);

    button {
      width: 50%;
    }

    @media only screen and (min-width: 800px) {
      width: 500px;
      position: relative;
      bottom: 0;
      margin: auto;
      margin-top: 24px;
    }
  }
}
