.wrapperLayout {
  display: flex;
  flex-direction: column;
  position: relative;

  .slogan {
    width: 100%;
    padding: 10px 0;
    color: var(--white-text-color);
    text-align: center;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: var(--secondary-text-color);
    font-size: var(--font-lg);
    font-style: italic;
    font-weight: 900;
    background: var(--primary-color);
    text-transform: uppercase;
  }

  .body {
    height: 100%;
    min-height: calc(100vh - 300px);
    max-width: 1300px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
