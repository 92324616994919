.wrapper {
  text-transform: uppercase;
  font-size: var(--font-md) !important;
  padding: 9.5px 16px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;

  &.solid {
    color: var(--primary-text-color);
    border: none;
  }

  &.outline {
    color: var(--white-text-color);
    background: transparent;
    border: 1px solid var(--white-text-color);
  }

  &.highLight {
    background: var(--high-light-color);
    color: var(--white-text-color);
    border: none;
  }
}
