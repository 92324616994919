.footerWrapper {
  background-color: var(--primary-color);
  position: relative;
  padding-top: 100px;

  @media only screen and (max-width: 800px) {
    padding-bottom: 110px;
  }

  .backToTop {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: rgba(18, 18, 18, 0.1);

    p {
      border: none;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      color: var(--white-text-color);
      text-transform: uppercase;
    }
  }

  .listItem {
    max-width: 800px;
    width: 100%;
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;

    a {
      min-width: 100px;
      text-align: center;
      width: fit-content;
      color: var(--white-text-color);
      text-decoration: none;
      font-size: var(--font-lg);
      font-weight: 300;
    }
  }

  .social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    padding-top: 0;
    max-width: 300px;
    width: 100%;
    margin: auto;

    img {
      width: 32px;
      height: 32px;
    }
  }
}
