/* NOTE: Font size class name */
.font-ms {
  font-size: var(--font-ms);
}

.font-us {
  font-size: var(--font-us);
}

.font-xs {
  font-size: var(--font-xs);
}

.font-sm {
  font-size: var(--font-sm);
}

.font-md {
  font-size: var(--font-md);
}

.font-lg {
  font-size: var(--font-lg);
}

.font-xl {
  font-size: var(--font-xl);
}

.font-xxl {
  font-size: var(--font-xxl);
}

.font-xxxl {
  font-size: var(--font-xxxl);
}

.font-4xl {
  font-size: var(--font-4xl);
}

.font-5xl {
  font-size: var(--font-5xl);
}

/* NOTE: Font color class name */
.color-white {
  color: var(--white-text-color);
}

.color-black {
  color: var(--primary-text-color);
}

.color-orange {
  color: var(--high-light-color);
}

.color-green {
  color: var(--sub-high-light-text-color);
}

.color-grey {
  color: var(--sub-text-color);
}

.color-blue {
  color: var(--high-light-text-color);
}

.color-red {
  color: var(--error-text-color);
}

/* NOTE: Font weight class name */
.font-light {
  font-weight: var(--font-light);
}

.font-medium {
  font-weight: var(--font-medium);
}

.font-bold {
  font-weight: var(--font-bold);
}

.error-message {
  font-size: var(--font-sm);
  color: var(--high-light-color);
  font-weight: var(--font-light);
  text-align: left;
  margin-top: 6px;
}

/* NOTE: Font Family */
.font-bebas {
  font-family: 'Bebas' !important;
}

.container {
  padding-left: 24px;
  padding-right: 24px;
}
