@import './font-size';
@import './font-weight';
@import './color';
@import './classname';
@import './reset';
@import './overwrite';
@import './box-shadow';
@import './font-family';
@import './space';
@import './border-radius';

html {
  position: fixed;
  height: 100vh;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

body {
  margin: 0 !important;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background: var(--white-text-color);
  caret-color: var(--primary-text-color);

  a {
    text-decoration: none;
  }

  .adm-image-img {
    object-fit: cover !important;
  }
}

.adm-switch-checked {
  .adm-switch-handle {
    background: var(--primary-color) !important;
  }
}

.rpv-core__page-layer {
  margin: auto;
}

.rpv-core__text-layer {
  display: none;
}

.adm-radio-icon {
  border-color: var(--secondary-text-color);
}

.adm-radio-checked {
  .adm-radio-icon {
    background-color: var(--sub-high-light-color) !important;
    border-color: var(--sub-high-light-color) !important;
  }
}

.adm-dot-loading {
  svg {
    height: 40px;
    width: 200px;
  }
}
