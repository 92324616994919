.container {
  .wrapper {
    width: 100%;
    display: flex;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    position: relative;
    background: var(--overlay-bg-color);

    .info {
      height: 200px;
      width: 400px;
      left: 0;
      padding: 40px 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      box-sizing: border-box;
    }

    .supportBackground {
      position: absolute;
      height: 100%;
      width: 50%;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
    }
  }

  .compareTable {
    animation: growDown 0.2s ease-in-out forwards;
    transform-origin: top center;
    margin-top: 37px;

    @keyframes growDown {
      0% {
        transform: scaleY(0);
      }

      80% {
        transform: scaleY(1.1);
      }

      100% {
        transform: scaleY(1);
      }
    }

    .blockInfo {
      display: flex;
      justify-content: space-around;
      margin: 16px auto;

      .card {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid var(--secondary-text-color);
        min-width: 117px;
        box-sizing: border-box;

        &.blackCard {
          background: linear-gradient(189deg, #00aeef 21.65%, #013641 105.54%);
          border: none;
          color: var(--white-text-color);
        }
      }
    }

    .block {
      display: flex;
      border-bottom: 1px solid var(--secondary-text-color);

      &:nth-child(2n + 1) {
        background-color: var(--overlay-bg-color);
      }

      .left {
        width: 50%;
        border-right: 1px solid var(--secondary-text-color);
        padding: 16px 10px 16px 24px;
        display: flex;
        align-items: center;
      }

      .right {
        width: 50%;
        padding: 16px 10px 16px 24px;
        display: flex;
        align-items: center;
      }
    }
  }
}
