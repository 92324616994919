.container {
  width: 100%;
  background: var(--white-text-color);
  position: sticky;
  top: 0;
  z-index: 1000;

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    min-height: 40px;
    padding: 13px 24px;
    transition: 0.3s;
    max-width: 1300px;
    box-sizing: border-box;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: var(--white-text-color);

    .logo {
      height: 100%;

      img {
        height: 100%;
        max-height: 35px;

        @media only screen and (min-width: 800px) {
          max-height: 60px;
        }
      }
    }

    .action {
      .stepItem {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }

        &:global(.adm-step-status-finish) {
          :global(.adm-step-icon-container) {
            border-color: var(--primary-color);
            background: var(--primary-color);

            span {
              color: var(--white-text-color) !important;
            }
          }

          :global(.adm-step-indicator) {
            &::after {
              background-color: var(--primary-color);
            }
          }
        }

        &:global(.adm-step-status-process) {
          :global(.adm-step-icon-container) {
            border-color: var(--primary-color);
            background: transparent;

            span {
              color: var(--primary-color) !important;
            }
          }

          :global(.adm-step-indicator) {
            &::after {
              background-color: var(--primary-color);
            }
          }
        }

        :global(.adm-step-indicator) {
          &::after {
            left: auto;
            right: -20px !important;
            height: 1px;
            background-color: var(--secondary-text-color);
          }
        }

        :global(.adm-step-icon-container) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          border-radius: 24px;
          border: 1px solid var(--secondary-text-color);
          background: transparent;

          span {
            line-height: 50px;
            color: var(--secondary-text-color);
            font-size: var(--font-sm);
          }
        }
      }
    }
  }
}
