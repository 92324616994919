.wrapper {
  padding: 12px;
  border-radius: 12px;
  background: var(--overlay-bg-color);
  position: relative;
  border: 1px solid transparent;

  input {
    font-size: var(--font-sm);
    font-weight: var(--font-light);
    color: var(--secondary-text-color);

    @media only screen and (min-width: 800px) {
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        width: calc(50% - 15px);
      }
    }
  }

  button {
    padding: 0;
    background: none;
    border: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    span {
      font-size: var(--font-md);
      color: var(--secondary-text-color);
      font-weight: bold;
    }
  }

  .removeButton {
    span {
      color: var(--primary-color);
    }
  }
}

.successMessage {
  font-size: 12px;
  color: var(--sub-high-light-color);
  margin-top: 4px;
  text-align: left;
  font-weight: var(--font-light);
  margin-left: 12px;
}

.errorMessage {
  font-size: 12px;
  color: var(--high-light-color);
  margin-top: 4px;
  text-align: left;
  font-weight: var(--font-light);
  margin-left: 12px;
}
