.wrapper {
  .clubWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    background: var(--overlay-bg-color);

    @media only screen and (min-width: 1300px) {
      justify-content: center;
      gap: 24px;
      width: 100vw;
      transform: translateX(calc((1300px - 100vw) / 2));
    }
  }

  .info {
    padding: 10px 24px 0 24px;

    @media only screen and (min-width: 800px) {
      text-align: center;
    }

    .promoCode {
      padding: 12px 0;
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      @media only screen and (min-width: 800px) {
        justify-content: center;
      }
    }

    .promotionInput {
      margin: 20px auto 10px auto;

      @media only screen and (min-width: 800px) {
        max-width: 400px;
      }
    }

    .promotionWrapper {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      gap: 12px;
      margin: 20px auto 10px auto;

      > div {
        width: 300px;
      }
    }
  }

  .tabs {
    position: sticky;
    top: 67px;
    padding: 10px 0;
    background: var(--white-text-color);
    z-index: 100;

    @media only screen and (min-width: 800px) {
      display: none;
    }
  }

  .plan {
    padding: 0 24px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    .item {
      width: calc(100% / 3 - 32px / 3);
    }

    @media only screen and (max-width: 800px) {
      padding: 0 12px;
    }

    @media only screen and (max-width: 1600px) {
      margin: 0;
      flex-wrap: wrap;

      .item {
        width: 100% !important;
      }
    }
  }

  .comparePlan {
    margin-top: 24px;
    display: block;

    @media only screen and (min-width: 800px) {
      display: none;
    }
  }

  .comparePlanDesktop {
    margin-top: 64px;
    display: block;

    @media only screen and (max-width: 800px) {
      display: none;
    }
  }

  .faqs {
    @media only screen and (min-width: 800px) {
      margin-top: 48px;
    }

    margin-top: 24px;
  }
}
