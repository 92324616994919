.wrapper {
  padding: 24px;

  .form {
    @media only screen and (min-width: 800px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .input {
        width: 300px;
      }
    }

    .input {
      margin-top: 12px;
    }

    .controller {
      position: fixed;
      bottom: 24px;
      width: calc(100% - 48px);

      @media only screen and (min-width: 800px) {
        width: fit-content;
        position: relative;
        bottom: 0;
        margin: auto;
        margin-top: 24px;
      }
    }
  }
}
