.ant-tooltip-inner {
  background-color: var(--blue-bg-color) !important;
  padding: 24px !important;
  border-radius: 12px !important;

  .tooltip-content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .close-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
  }
}

.ant-tooltip {
  max-width: 358px;
  width: 100%;

  .ant-tooltip-arrow {
    &::before {
      background: var(--blue-bg-color) !important;
    }
  }
}
