.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background: var(--overlay-bg-color);

  @media only screen and (min-width: 1300px) {
    justify-content: center;
    gap: 24px;
    width: calc(100vw - 48px);
    transform: translateX(calc((1300px - 100vw) / 2));
  }

  .wrapper {
    max-width: 1300px;
    width: 100%;

    @media only screen and (min-width: 800px) {
      > p {
        text-align: center;
      }
    }

    @media only screen and (min-width: 800px) {
      background-color: transparent;
      margin-bottom: 24px;
    }

    .question {
      margin-top: 26px;

      :global(.adm-list-body) {
        border: none;
      }

      :global(.adm-list-item) {
        background-color: var(--overlay-bg-color);
        padding-left: 0;

        &:active {
          background-color: var(--overlay-bg-color);
        }

        @media only screen and (min-width: 800px) {
          // background-color: transparent;

          &:active {
            // background-color: transparent;
          }
        }
      }

      :global(.adm-list-item-content) {
        padding-right: 0;
        border-color: var(--secondary-text-color);

        &::after {
          border: none;
        }
      }

      :global(a.adm-list-item:active:not(.adm-list-item-disabled)::after) {
        display: none;
      }

      :global(.adm-list-item-content-main) {
        padding: 24px 0;
        font-size: var(--font-lg);
        font-weight: var(--font-medium);
        line-height: normal;
        color: var(--primary-text-color);
      }

      :global(.adm-collapse-panel-content-inner .adm-list-item-content) {
        border: none;
      }

      :global(
          .adm-collapse-panel-content-inner .adm-list-item-content .adm-list-item-content-main
        ) {
        padding-top: 0;
        font-weight: var(--font-light);
      }
    }
  }
}
