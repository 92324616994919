.searchClub {
  position: relative;

  .searchWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;

    .search {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: var(--font-md);
      padding: 16px 24px;
      border: 1px solid var(--primary-text-color);
      border-radius: var(--space-large);
      cursor: pointer;

      &.active {
        border: 1px solid var(--primary-color);
        background-color: white;
      }

      .searchClubIcon svg {
        width: 24px;
        height: 24px;
        color: var(--primary-color);
      }

      .searchClubInput {
        margin-left: 15px;

        p {
          text-transform: uppercase;
        }
      }
    }

    .closeIcon {
      position: absolute;
      right: 24px;
    }
  }

  .searchClubResult {
    width: 100%;
    display: none;
    background-color: var(--white-text-color);
    border-radius: var(--space-small);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;

    &.show {
      transform: translateY(0);
      display: block;
    }

    .searchClubList {
      .searchClubItem {
        font-size: var(--font-sm);
        font-weight: var(--font-light);
        align-self: center;
        text-align: center;
        text-transform: uppercase;
        padding: 8px;
        cursor: pointer;

        &:hover {
          background-color: var(--primary-color);
          color: var(--white-text-color);

          &:nth-child(1) {
            border-top-left-radius: var(--radius-small);
            border-top-right-radius: var(--radius-small);
          }

          &:nth-last-child(1) {
            border-bottom-left-radius: var(--radius-small);
            border-bottom-right-radius: var(--radius-small);
          }
        }
      }
    }
  }
}
