.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  overflow: hidden;

  .cardWrapper {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    position: relative;

    background: rgba(18, 18, 18, 0.2);

    p {
      text-align: center;
      text-transform: uppercase;
      line-height: normal;
      transform: rotate(-4deg);
      white-space: pre-wrap;
      font-size: 26px;
    }

    .button {
      position: absolute;
      bottom: 33px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
