.wrapperContainer {
  display: grid;
  align-items: center;
  padding: 0 24px;

  .searchWrapper {
    margin: 0 auto 16px auto;
    max-width: 600px;
    width: 100%;
  }

  .clubNearYouTitle {
    font-weight: 700;
    font-size: var(--font-xxl);
    margin-top: 10px;
  }

  .clubCardList {
    margin-top: 12px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;

    .clubCard {
      width: 100%;
    }

    @media only screen and (min-width: 1200px) {
      flex-direction: row;
      flex-wrap: wrap;

      .clubCard {
        width: calc(100% / 3 - 24px / 2) !important;
      }
    }

    @media only screen and (min-width: 800px) {
      flex-direction: row;
      flex-wrap: wrap;

      .clubCard {
        width: calc(50% - 6px);
      }
    }
  }
}
