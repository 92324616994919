.listItem {
  overflow: visible !important;

  :global(.adm-page-indicator-dot-active) {
    background: var(--white-text-color);
  }

  :global(.adm-page-indicator-dot) {
    width: 6px;
    height: 6px;
    border-radius: 8px;
    background: transparent;
    border: 1px solid var(--white-text-color);
  }

  :global(.adm-page-indicator-dot-active) {
    background: var(--secondary-color);
  }

  :global(.adm-swiper-item) {
    padding-left: 24px;
    width: calc(100% - 48px);
    box-shadow: none !important;
  }
}
