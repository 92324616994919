:root {
  --primary-color: #00aeef;
  --secondary-color: #ffffff;
  --primary-bg-color: #f4f4f4;
  --high-light-color: #f36f2c;
  --sub-high-light-color: #b2d700;
  --main-bg-color: #e7f5f7;
  --overlay-bg-color: rgba(0, 174, 239, 0.1);
  --blue-bg-color: #b4f2ff;

  /** Text Color*/
  --primary-text-color: #121212;
  --secondary-text-color: #555859;
  --sub-text-color: #555859;
  --white-text-color: #ffffff;
  --high-light-text-color: #00aeef;
  --sub-high-light-text-color: #b2d700;
  --error-text-color: #ff4646;
  --sub-bg-color: #ccf5f4;
}
