.wrapper {
  display: flex;
  align-items: center;

  .switch {
    :global(.adm-switch-checkbox) {
      background: var(--secondary-text-color) !important;
      border: 2px solid var(--secondary-text-color) !important;

      &::before {
        background: var(--secondary-text-color) !important;
      }
    }

    :global(.adm-switch-handle) {
      background: var(--primary-color);
      width: 19px;
      height: 19px;
      transform: translateX(4px);
      top: 7px;
    }

    :global(.adm-switch-inner) {
      color: var(--white-text-color) !important;
      font-size: var(--font-xs);
      font-weight: var(--font-light);
    }
  }
}
